<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex lg11 xl11 text-left>
        <v-card color="#F7F7F7" tile flat>
          <v-layout wrap justify-center pa-4>
            <v-flex xs12 pt-6>
              <span class="itemHeading2"> ACCOMODATION SUMMARY </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <span class="itemTags" style="color: #9e9e9e"> Package </span>
            </v-flex>
            <v-flex xs12 pt-2>
              <span class="tableValue1">
                {{ $route.query.name }}
              </span>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 14px;
                  color: #414141;
                "
              >
                ( {{ $route.query.caption }} )
              </span>
            </v-flex>
            <v-flex xs12 pt-4>
              <span class="itemTags" style="color: #9e9e9e">
                Selected Date
              </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <span class="itemHeading2"> {{ formatDate(date) }} </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center px-4 pb-4 v-if="date">
            <v-flex xs12>
              <span class="itemTags" style="color: #9e9e9e">
                Availability
              </span>
            </v-flex>
            <v-flex xs12>
              <v-card
                v-for="item in storage"
                :key="item._id"
                flat
                :ripple="false"
                class="my-2"
                color="#68D389"
              >
                <v-layout wrap justify-center pa-6>
                  <v-flex xs12>
                    <span class="tableValue1" style="color: #ffffff">
                      <span v-if="item.freeCount != 0">
                        {{ item.freeCount }}
                      </span>
                      <span v-else> No </span>
                      Rooms Available
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 py-4>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12>
              <span
                class="itemTags"
                style="color: #000000"
                v-if="storage.length < 1"
              >
                No Rooms Available on {{ formatDate(date) }}
              </span>
            </v-flex>
            <v-flex xs12 v-if="selectedSlot.freeCount > 0">
              <v-layout wrap>
                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    type="number"
                    v-model="numberOfRooms"
                    outlined
                    hide-details
                    label="No. of rooms required"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    v-model="guestName"
                    outlined
                    hide-details
                    label="Guest Name *"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    type="number"
                    v-model="numberOfAccompanyigPersons"
                    outlined
                    hide-details
                    label="No. of persons accompanying*"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-text-field
                    type="number"
                    placeholder="Guest Phone Number"
                    label="Guest Phone No.*"
                    outlined
                    dense
                    :rules="[rules.required, rules.min]"
                    v-model="guestPhone"
                    hide-details
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    v-model="referee"
                    outlined
                    hide-details
                    label="Referred by *"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-text-field
                    type="number"
                    placeholder="Reference person's phone no."
                    label="Reference person Phone *"
                    outlined
                    dense
                    v-model="refereePhone"
                    hide-details
                    :rules="[rules.required, rules.min]"
                  >
                  </v-text-field>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    v-model="email"
                    outlined
                    hide-details
                    label="Email address"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-select
                    outlined
                    dense
                    placeholder="Food preference"
                    label="Food preference"
                    v-model="foodPreference"
                    :items="foods"
                    multiple
                    hide-details
                    item-text="name"
                    item-value="name"
                    class="text-left"
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-text-field
                    class="textField2"
                    dense
                    type="number"
                    v-model="numberOfVehicles"
                    outlined
                    hide-details
                    label="No. of vehicles"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 py-4>
                  <v-combobox
                    placeholder="Vehicle Numbers"
                    label="Vehicle Numbers"
                    v-model="vehicleNumbers"
                    outlined
                    :items="keyArray"
                    multiple
                    dense
                    small-chips
                    hint="Note: Type number and press Enter key"
                  >
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <span class="pa-1">
                        <v-chip v-bind="attrs" :input-value="selected" small>
                          <span
                            class="pr-2"
                            style="font-family: opensansregular; font-size: 13px"
                          >
                            {{ item }} </span
                          >&nbsp;
                          <v-icon small @click="parent.selectItem(item)">
                            mdi-close-circle
                          </v-icon>
                        </v-chip>
                      </span>
                    </template>
                    <template v-slot:append>
                      <span></span>
                    </template>
                  </v-combobox>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-textarea
                    class="textField2"
                    dense
                    rows="3"
                    v-model="details"
                    outlined
                    label="Any other details"
                    required
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 py-4>
                  <v-btn
                    block
                    dark
                    depressed
                    color="#68D389"
                    :ripple="false"
                    @click="validateInput"
                  >
                    Submit
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- <template v-if="selectedSlot.freeCount > 0">
              <v-flex xs12 py-4>
                <v-text-field
                  class="textField2"
                  dense
                  type="number"
                  v-model="numberOfRooms"
                  outlined
                  label="No. of rooms required"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 py-4>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="reservedFor"
                  outlined
                  label="Reserved for (required)"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 py-4>
                <v-btn
                  block
                  dark
                  depressed
                  color="#68D389"
                  :ripple="false"
                  @click="validateInput"
                >
                  Submit
                </v-btn>
              </v-flex>
            </template> -->
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["date", "storage", "programme", "programData"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      selectedSlot: {},
      guest: [],
      numberOfRooms: null,
      reservedFor: null,
      numberOfAccompanyigPersons: null,
      guestName: null,
      guestPhone: '',
      referee: null,
      refereePhone: '',
      email: null,
      foodPreference: null,
      numberOfVehicles: null,
      vehicleNumbers: null,
      details: null,
      foods: [
        {
          name: "Vegetarian"
        }, 
        {
          name: "Non-Vegetarian"
        }],
      // foods: ["Vegetarian", "Non-Vegetarian"],
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length === 10 || "Max 10 characters",
      },
      keyArray: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  created() {
    if (this.storage[0].status == "Active") {
      this.selectedSlot = this.storage[0];
    }
  },
  watch: {
    storage: {
      handler(val) {
        if (val[0].status == "Active") {
          this.selectedSlot = val[0];
        }
      },
      deep: true,
    },
    numberOfRooms() {
      if (this.numberOfRooms > this.selectedSlot.freeCount) {
        this.msg =
          "Please select maximum of " +
          this.selectedSlot.freeCount +
          " rooms";
        this.numberOfRooms = null;
        this.showSnackBar = true;
      }
    },
  },
  methods: {
    validateInput() {
      if (this.numberOfRooms > this.selectedSlot.freeCount) {
        this.msg =
          "Please select maximum of " +
          this.selectedSlot.freeCount +
          " rooms";
        this.showSnackBar = true;
      } 
      else if (!this.numberOfRooms) {
        this.msg = "Please provide the no. of rooms required";
        this.showSnackBar = true;
      } 
      else if (!this.guestName) {
        this.msg = "Please provide the guest name";
        this.showSnackBar = true;
      } else if (!this.guestPhone) {
        this.msg = "Please provide the guest phone";
        this.showSnackBar = true;
      } else if (!this.numberOfAccompanyigPersons) {
        this.msg = "Please provide the no. of people accompanying";
        this.showSnackBar = true;
      }
      else if (!this.referee) {
        this.msg = "Please provide the reference person's name";
        this.showSnackBar = true;
      }
      else if (!this.refereePhone) {
        this.msg = "Please provide the reference person's phone";
        this.showSnackBar = true;
      }
      
      else this.addToCart();
    },

    addToCart() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/reserve/ib/add",
        data: {
          programme: this.$route.query.type,
          bookingDate: this.date,
          slotDetail: this.selectedSlot._id,
          numberOfRooms: Number(this.numberOfRooms),
          // reservedFor: this.reservedFor,
          numberOfAccompanyigPersons: this.numberOfAccompanyigPersons,
          guestName: this.guestName,
          guestPhone: this.guestPhone,
          referee: this.referee,
          refereePhone: this.refereePhone,
          email: this.email,
          foodPreference: this.foodPreference,
          numberOfVehicles: this.numberOfVehicles,
          vehicleNumbers: this.vehicleNumbers,
          details: this.details,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.numberOfRooms = null;
            this.reservedFor = null;

            this.$router.push("/Admin/IBReservationList/dateWisePrograms");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            if (response.data.expired) {
              this.$router.push("/programIB/" + this.$route.query.type);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style>
.v-application .primary--text {
  color: #68d389 !important;
  caret-color: #68d389 !important;
}

#slotInput {
  text-align: center;
}
</style>
